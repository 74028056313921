import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { PosthogService } from '../posthog.service';
import { FeatureScope, FeatureScopeSandbox, PostHogEventsEnum } from '@smooved/core';
import { MoveActionTypes } from '@app/move/state/move.actions';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class PosthogEffects {
    public desktopTransactionEnergyMeterReadingsAttachUploaded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsAttachmentUploaded),
                switchMap(({ transactionId }: { transactionId: string }) =>
                    this.featureScopeSandbox.hasAccess(FeatureScope.SmoovedCompanion).pipe(
                        tap((hasAccess: boolean) => {
                            if (hasAccess) {
                                this.posthogService.sendEventForTransaction(
                                    PostHogEventsEnum.DesktopEnergyMetersEditAttachmentUploaded,
                                    transactionId
                                );
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    public desktopEnergyMeterReadingsEditManual$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsManual),
                switchMap(({ transactionId }: { transactionId: string }) =>
                    this.featureScopeSandbox.hasAccess(FeatureScope.SmoovedCompanion).pipe(
                        tap((hasAccess: boolean) => {
                            if (hasAccess) {
                                this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersEditManual, transactionId);
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    public desktopEnergyMeterReadingsEditConfirmed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.EnergyMeterReadingsConfirmed),
                switchMap(({ transactionId }: { transactionId: string }) =>
                    this.featureScopeSandbox.hasAccess(FeatureScope.SmoovedCompanion).pipe(
                        tap((hasAccess: boolean) => {
                            if (hasAccess) {
                                this.posthogService.sendEventForTransaction(PostHogEventsEnum.DesktopEnergyMetersConfirmed, transactionId);
                            }
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly posthogService: PosthogService,
        private readonly featureScopeSandbox: FeatureScopeSandbox
    ) {}
}
